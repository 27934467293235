<template>
	<el-container>
		<el-header class="header">
			<div></div>
			<span @click="$refs.userPicker.show()" style="cursor: pointer;">
				<i class="el-icon-user-solid" style="margin-right: 5px"></i>
				{{ user?.nickname || '点击选择用户' }}
			</span>
		</el-header>
		<el-container>
			<el-aside
				width="200px"
				style="background-color: rgb(238, 241, 246)"
			>
				<el-menu :router="true" :default-openeds="['1']">
					<el-submenu index="1">
						<template slot="title">
							<i class="el-icon-message"></i>
							审批中心
						</template>
						<el-menu-item index="/flowable/start">
							发起申请
						</el-menu-item>
						<el-menu-item index="/flowable/selfApply">
							我的申请
						</el-menu-item>
						<el-menu-item index="/flowable/selfTodo">
							我的待办
						</el-menu-item>
						<el-menu-item index="/flowable/selfDone">
							我的已办
						</el-menu-item>
						<el-menu-item index="/flowable/selfAbout">
							抄送我的
						</el-menu-item>
					</el-submenu>
					<el-menu-item index="/groups">
						<i class="el-icon-setting"></i>
						流程管理
					</el-menu-item>
				</el-menu>
			</el-aside>
			<el-main>
				<router-view></router-view>
			</el-main>
		</el-container>
		<user-picker
			type="user"
			ref="userPicker"
			:selected="select"
			@ok="selected"
		></user-picker>
	</el-container>
</template>

<script>
import UserPicker from './components/UserPicker'
export default {
	components: { UserPicker },
	data() {
		return {
			select: [],
			user: ''
		}
	},
	mounted() {
		let operator = sessionStorage.getItem('operator')
		let nickname = sessionStorage.getItem('nickname')
		if (operator && nickname) {
			this.user = {
				id: operator,
				nickname: nickname
			}
			this.select.push(this.user)
		}
	},
	methods: {
		selected(select) {
			this.select = select
			this.user = select.length > 0 ? select[0] : ''
			sessionStorage.setItem('operator', this.user.id)
			sessionStorage.setItem('nickname', this.user.nickname)
			this.$router.replace(`/`)
		}
	}
}
</script>

<style lang="less" scoped>
.el-header {
	background-color: #fff;
	color: #333;
	line-height: 60px;
	width: 100%;
	overflow: hidden;
	border-bottom: 1px solid #ddd;
	box-shadow: 0 1px 4px #00152914;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
